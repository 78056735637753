import Vue from "vue";
import App from "./App";
import router from "./router";
import vuetify from "./plugins/vuetify";
import device from "vue-device-detector";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(device);

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: false
});

// Lazy Load for Images
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

/* eslint-disable no-new */
new Vue({
  el: "#app",

  data: {},

  router,
  template: "<App/>",

  components: {
    App
  },

  vuetify,
  methods: {},
  mounted() {}
});
